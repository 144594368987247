import hostOmni from "@/config/host";
import axios from "axios";

let webSocketProvider, CampanhaConnection;

const bearerToken = JSON.parse(localStorage.getItem("login"))?.token;
const watchingProgress = [];
const callbacks = {};

const start = async () => {
	if (!CampanhaConnection) {
		webSocketProvider = await axios.get("/websocket-provider").then(response => response.data);
		switch (webSocketProvider) {
			default: break;
			case "SignalR":
				CampanhaConnection = await import("@microsoft/signalr").then(signalR => {
					let connection = new signalR.HubConnectionBuilder()
						.withUrl(`${hostOmni}/CampanhaHub`, { accessTokenFactory: () => bearerToken })
						.configureLogging(signalR.LogLevel.Error)
						.withAutomaticReconnect()
						.build();

					connection.serverTimeoutInMilliseconds = 180000;
					connection.keepAliveIntervalInMilliseconds = 60000;

					return connection;
				});

				break;
			case "SDWebSocket":
				CampanhaConnection = await import("@/assets/lib/sd-websocket/SDWebSocket").then(sdWebSocket => {
					return new sdWebSocket.HubConnection()
						.withUrl(`${hostOmni.replace("https://", "wss://")}/CampanhaHub`)
						.withAccessToken(bearerToken)
						.withAutomaticReconnect();
				});

				break;
		}
	}

	CampanhaConnection.onreconnected(() => {
		watchingProgress.forEach(campanhaId => {
			watchProgress(campanhaId, false);
		});
	});

	CampanhaConnection.on("CampanhaStatus", callbacks["campanhaStatus"] ?? (() => { }));
	CampanhaConnection.on("DestinatarioAdicionado", callbacks["destinatarioAdicionado"] ?? (() => { }));
	CampanhaConnection.on("DestinatarioComSucesso", callbacks["destinatarioComSucesso"] ?? (() => { }));
	CampanhaConnection.on("DestinatarioComErro", callbacks["destinatarioComErro"] ?? (() => { }));

	return await CampanhaConnection.start();
}

const stop = () => {
	CampanhaConnection.off("CampanhaStatus");
	CampanhaConnection.off("DestinatarioAdicionado");
	CampanhaConnection.off("DestinatarioComSucesso");
	CampanhaConnection.off("DestinatarioComErro");
	return CampanhaConnection.stop();
}

const onCampanhaStatus = callback => {
	callbacks.campanhaStatus = callback;
};

const onDestinatarioAdicionado = callback => {
	callbacks.destinatarioAdicionado = callback;
};

const onDestinatarioComSucesso = callback => {
	callbacks.destinatarioComSucesso = callback;
};

const onDestinatarioComErro = callback => {
	callback.destinatarioComErro = callback;
};

const watchProgress = (campanhaId, updateController = true) => {
	if (updateController && watchingProgress.indexOf(campanhaId) == -1) watchingProgress.push(campanhaId);
	if (CampanhaConnection.status == "Disconnected") return;
	return CampanhaConnection.send("WatchProgress", campanhaId);
};

const unwatchProgress = (campanhaId, updateController = true) => {
	if (updateController && watchingProgress.find(item => item == campanhaId)) watchingProgress.splice(watchingProgress.indexOf(campanhaId), 1);
	if (CampanhaConnection.status == "Disconnected") return;
	return CampanhaConnection.send("UnwatchProgress", campanhaId);
};

const retomarCampanha = (campanhaId) => {
	if (CampanhaConnection.status == "Disconnected") return;
	return CampanhaConnection.send("RetomarCampanha", campanhaId);
};

const interromperCampanha = (campanhaId) => {
	if (CampanhaConnection.status == "Disconnected") return;
	return CampanhaConnection.send("InterromperCampanha", campanhaId);
};

export default {
	CampanhaConnection,
	start,
	stop,
	watchProgress,
	unwatchProgress,
	retomarCampanha,
	interromperCampanha,
	onCampanhaStatus,
	onDestinatarioAdicionado,
	onDestinatarioComSucesso,
	onDestinatarioComErro
}